:root {
  --dark: #43425D;
  --lightpurple: #A3A0FB;
  --lightpurple1: #734FC6;
  --lightpurple2: #c2b0e2;
  --lightpurple3: rgb(194 176 226 / 30%);
  --purple: #6639B7;
  --darkpurple: #381971;
  --white: #FFFFFF;
  --black: #000000;
  --gray: #F0F0F7;
  --light-gray: #f1f1f3;
  --dark-gray: #4D4F5C;
  --pale-dark-gray: #00000076;
  --light-gray-pale: rgb(20 25 29 / 10%);
}

body,
#root,
.ant-layout {
  font-family: "Noto Sans", sans-serif;
  height: 100vh;
  margin: 0;
  min-width: 380px;
}

/* Spinner */

.ant-spin-nested-loading.spin-active {
  z-index: 1051;
}

.ant-spin-nested-loading.spin-idle {
  z-index: 0;
}

/* Input */

.ant-input,
.ant-input-affix-wrapper {
  border-radius: 4px;
}

/* Button */

.ant-btn-primary:disabled {
  border-color: var(--purple);
  background-color: var(--purple);
  color: white;
  opacity: 50%;
}

.ant-btn-default:disabled {
  opacity: 50%;
}

.ant-btn-default:hover {
  background-color: var(--light-gray);
}

.ant-btn.ant-btn-circle.ant-btn {
  /* min-width: 30px;
  width: 30px;
  height: 30px; */
}

/* Ant Select */

.ant-select {
  min-width: 100px;
}

.ant-select-dropdown {
  padding: 0;
  border-radius: 0;
  position: relative;
}

.ant-select-item {
  border-radius: 0 !important;
}

.ant-select-item-option:nth-child(odd) {
  background-color: var(--gray);
}

.ant-select-item-option:nth-child(even) {
  background-color: var(--white);
}

.ant-select-item-option-selected {
  background-color: var(--darkpurple);
  color: var(--white);
}

.ant-select-item-option-state {
  color: var(--white) !important;
}

.ant-select-multiple .ant-select-selection-item {
  background-color: var(--white);
}

.ant-select-dropdown .ant-select-item-option-active .ant-select-item-option-content {
  color: var(--white);
}

/* Dropdown */

.ant-dropdown .ant-dropdown-menu {
  padding: 0;
  border-radius: 0;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active {
  background-color: var(--purple) !important;
  color: var(--white);
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-danger:not(.ant-dropdown-menu-item-disabled) {
  color: var(--black);
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-danger:not(.ant-dropdown-menu-item-disabled):hover {
  background-color: #ff4d4f !important;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  border-radius: 0;
  padding: 7px 12px;
  min-width: 10em;
  text-align: right;
}


/* Tabs */

.tab-common .ant-tabs-tab {
  border-radius: 0 !important;
  display: block !important;
}

.tab-common .ant-tabs-nav-wrap, .tab-common .ant-tabs >.ant-tabs-nav {
  background-color: var(--gray);
}

.tab-common .ant-tabs,
.tab-common .ant-tabs .ant-tabs-content-holder,
.tab-common .ant-tabs .ant-tabs-content {
  height: 100%;
}

.tab-common .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--purple) !important;
  font-weight: bold;
}

.tab-common .ant-tabs-card>.ant-tabs-nav .ant-tabs-tab {
  color: var(--purple);
  border: none;
  background-color: var(--gray) !important;
  border-bottom: 0.4em solid var(--purple);
  border-top: 0.4em solid var(--gray);
  font: normal normal normal 1.1em/24px Noto Sans;
  padding: 12px 16px 5.5px 16px;
}

.tab-common .ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active {
  background-color: var(--white) !important;
  border-bottom: 0.4em solid var(--white);
  border-top: 0.4em solid var(--purple);
  padding: 7px 16px 5.5px 16px;
}

.tab-common .ant-tabs .ant-tabs-tabpane {
  height: 100%;
}

/* Table */

.ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  width: 0;
}

.ant-table-header table {
  border-spacing: 1px 0;
}

.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-column-sorter {
  color: var(--white);
}

.ant-table-cell-row-hover {
  color: var(--white);
}

.ant-table-wrapper .ant-table-thead>tr>th {
  border-bottom: 0;
}

.ant-table-wrapper .ant-table.ant-table-small {
  font-size: 0.9em;
}

tr.ant-table-row {
  cursor: pointer;
}

.ant-table-column-sorter {
  display: none;
}

/* Pagination */

.ant-pagination li.ant-pagination-item,
.ant-pagination-next,
.ant-pagination-prev {
  border-color: var(--lightpurple2) !important;
  border-right: 0;
}

.ant-pagination li.ant-pagination-item {
  border-radius: 0 !important;
}

.ant-pagination-next,
.ant-pagination-prev {
  border: 1px solid var(--lightpurple2) !important;
  font-weight: 600;
}

.ant-pagination-prev {
  border-right: 0 !important;
  border-radius: 3px 0 0 3px !important;
}

.ant-pagination-next {
  border-radius: 0 3px 3px 0 !important;
}

.ant-pagination .ant-pagination-item-active {
  background-color: var(--purple);
}

.ant-pagination .ant-pagination-item-active a {
  color: white !important;
}

.ant-pagination-next button:not(.ant-pagination .ant-pagination-disabled .ant-pagination-item-link),
.ant-pagination-prev button:not(.ant-pagination .ant-pagination-disabled .ant-pagination-item-link),
.ant-pagination-item a {
  color: var(--purple) !important;
  border-radius: 0 !important;
  font-weight: 600;
}

.ant-pagination.ant-pagination-mini .ant-pagination-options {
  position: absolute;
  left: 0;
}

.ant-pagination-total-text {
  left: 8em;
  position: absolute;
}

.ant-pagination-options .ant-select-selector {
  border-radius: 3px !important;
  border-color: var(--lightpurple2) !important;
}

.ant-pagination-options .ant-select-arrow {
  color: var(--purple) !important;
}

/* Modal */

.ant-modal .ant-modal-close {
  top: 0;
  inset-inline-end: 0;
  height: 22px;
  width: 22px;
}


/* Scroll */

.ant-table-body {
  scrollbar-width: auto;
  scrollbar-color: auto;
}

::-webkit-scrollbar, .ant-table-body::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}

::-webkit-scrollbar-track, .ant-table-body::-webkit-scrollbar-track {
  background-color: rgb(227 220 244);
}

::-webkit-scrollbar-thumb, .ant-table-body::-webkit-scrollbar-thumb {
  background-color: var(--lightpurple1);
  border-radius: 10px;
}

#mainNav ::-webkit-scrollbar-track {
  /* background-color: rgba(5, 5, 5, 0.06); */
  background-color: var(--lightpurple2);
}



/********** Media queries **********/
@media screen and (min-width: 767px) {

  #logoUMC1 {
      display: none;
  }

  #logoUMC2 {
      display: block;
      margin: 10px 20px 10px 70px;
  }

  #logoField {
      flex-direction: row;
      align-items: start;
  }

  #mainNav button span {
      display: initial;
  }

  .button {
      justify-items: left;
      /* width: 244px; */
  }

  #mainFooter p {
      /* font: normal normal lighter 12px/20px Noto Sans; */
      font-size: 10px;
  }

  #mainFooter p a {
      color: var(--white);
      /* font: normal normal normal 12px/20px Noto Sans; */
      text-decoration: none;
      font-size: 10px;
  }

  .toggleButton {
      order: 3;
  }
}

@media screen and (min-width: 1024px) {
  #mainFooter p a {
      color: var(--white);
      text-decoration: none;
  }
}

@media screen and (max-width: 1206px) {
  .avator-btn {
      display: none;
  }
}

@media screen and (max-width: 550px) {
  .login-form-button {
      width: auto;
  }
}

@media screen and (max-width: 767px) {
  .expand #logoField {
      flex-direction: column;
  }
}

@media screen and (min-height: 550px) {
  .ant-table-body {
      max-height: "16em" !important;
  }
}

@media only screen and (max-width: 576px) {
  .ant-pagination-total-text {
      left: 0;
  }
}