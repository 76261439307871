/********** Footer **********/
#mainFooter {
    color: var(--white);
    background-color: var(--dark);
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 1;
}

#mainFooter p a {
    color: var(--white);
    /* font: normal normal normal 10px/20px Noto Sans; */
    text-decoration: none;
}

.ant-layout-footer {
    padding: 0px 50px
}

#footerTerms {
    margin-left: 10px;
    opacity: 0.7;
}

#footerContact {
    margin-right: 10px;
    opacity: 0.7;
}

/* Sidebar */

.button {
    width: calc(100% - 16px);
    color: var(--white);
    background-color: var(--purple);
    border-radius: 6px;
    border: 2px solid rgba(255, 255, 255, 0.4);
    transition: background-color 0.3s;
    display: grid;
    grid-template-columns: 50px 1fr;
    align-items: center;
    justify-items: center;
    /* font: normal normal normal 15px/20px Noto Sans; */
}

.buttonSystem {
    height: 46px;
    margin: 8px 8px 0 8px;
    justify-items: left;
}

.buttonMenu {
    height: 4.5em;
    margin: 7px 8px;
    justify-items: left;
    padding: 0 15px;
}

.expand .button {
    width: calc(100% - 14px);
    justify-items: end;
}

#logoUMC1,
#logoUMC2 {
    margin: 8px;
}

#logoUMC2 {
    display: none;
}

.logo-umc-min {
    margin: 8px;
}

.logo-umc-max {
    margin: 10px auto 10px;
}

.expand #logoField {
    display: flex;
    flex-direction: column-reverse;
    align-items: end;
}

.collapse #logoField {
    flex-direction: row;
    align-items: start;
    display: flex;
    justify-content: center;
}

#adminMode .ant-btn-icon img,
#configMenu .ant-btn-icon img,
#bottom-nav .ant-btn-icon img {
    width: 35px;
    height: 35px;
}

#adminMode {
    padding: 15px;
}

#adminMode .mode-label {
    word-break: break-word;
}

.collapse .toggleButton {
    position: absolute;
    right: 0;
}

.toggleButton {
    background-color: var(--purple);
    border: none;
    margin: 8px 2px;
    float: right;
    transition: rotate 0.3s;
}

.expand .ant-btn-default.toggleButton:hover,
.collapse .ant-btn-default.toggleButton:hover {
    background-color: var(--purple);
}

.toggleButton img:hover {
    height: 90%;
    width: 90%;
}

#mainNav {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-bottom: 20px;
    justify-content: space-between;
}


#mainNav nav button:hover,
#generalMenu button:hover,
#mainNav nav button.active {
    background-color: var(--darkpurple);
    border: 1px solid var(--white);
    color: var(--white);
}

#bottom-nav {
    position: absolute;
    bottom: 0;
    width: 100%;
}

#configMenu {
    max-height: calc(100vh - 304px);
    overflow-y: auto;
    overflow-x: hidden;
}

.ant-layout .ant-layout-sider {
    height: calc(100% - 30px);
}

.link-icon {
    position: relative;
    top: 75%;
    transform: rotate(45deg);
    font-size: 12px;
}

.link-label {
    grid-template-columns: 1fr 2px;
    display: grid !important;
    width: 100%;
    justify-items: left;
}

/* Header */

.header-icon {
    height: 20px;
    width: 20px;
}

.avator-btn {
    vertical-align: middle;
    border: none;
}

.header-item .ant-btn-compact-last-item {
    border-radius: 50% !important;
}

li.ant-dropdown-menu-item {
    padding: 5px 12px;
    text-align: left !important;
}

.header-item .ant-btn-compact-item {
    border: none;
}

.header-item .ant-btn-compact-first-item {
    background-color: white !important;
    box-shadow: none !important;
    cursor: default;
    padding: 6px 4px;
}

.header-item .ant-btn-compact-last-item {
    border-radius: 50% !important;
}

/* Sub-header */

.sub-header {
    margin: 15px 50px;
    color: var(--purple);
    /* font-size: 18px; */
    font-size: 1.3em;
}

/* Content */

.ant-form-vertical .ant-form-item-label,
.oxe-login-label {
    width: 35%;
    margin: 0 auto;
}

.ant-form-item-label>label,
.oxe-login-label {
    color: #43425D;
}

.ant-form-item {
    margin-bottom: 16px !important;
}

.oxe-login-label {
    text-align: left;
    padding-bottom: 5px;
}

.field-wrap {
    padding: 6px 0;
}

.ale-logo {
    height: 65px;
    width: 185px;
}

.login-form-button {
    width: 15%;
    margin-top: 10px;
}

.login-status {
    color: var(--purple);
    font-size: 14px;
}


/* Tabs & contents */

.tab-common #inner-tab-content {
    padding: 0 5px;
    height: inherit;
}

.tab-common .extra-tab-actions {
    margin-left: 5px;
}

/* Input custom */

.input-text {
    width: 35%;
    padding: 6px 10px;
    height: 34px;
    font-size: 12px;
}

.inner-tab-hd-container .ant-input-affix-wrapper {
    padding: 2px 11px;
    /* To be removed? */
    /* width: 90%; */
    height: 2.1em;
}

.ant-input-affix-wrapper.user-search {
    width: 10rem;
    min-width: 75px;
    margin-right: 10px;
}

/* Table */

.table-striped-rows tr:nth-child(odd) td,
tr:nth-child(odd).dd-hover-active .ant-table-cell-row-hover {
    background-color: var(--white);
}

.table-striped-rows tr:nth-child(even) td,
tr:nth-child(even).dd-hover-active .ant-table-cell-row-hover {
    background-color: var(--light-gray);
}

.tbl-col-search,
#inner-tab-content .ant-select-single {
    width: 80%;
    min-width: inherit;
}

.inner-tab-hd-container .ant-input-affix-wrapper,
.inner-tab-hd-container .ant-input,
.ant-table-column-title .ant-select-single .ant-select-selector {
    border-radius: 0;
    border-color: var(--purple);
}

.ant-table-column-title .ant-input {
    padding: 2px 4px;
    font-size: 0.9em;
    cursor: auto;
    border-radius: 0;
}

.ant-table-column-title .ant-select {
    height: 21.5px;
}

.ant-table-column-title .ant-select-selector {
    padding: 2px 4px;
    font-size: 0.9em;
}

.ant-btn.cont-header-btn {
    margin: 0 5px;
    padding: 2px 2em;
}

.inner-tab-hd-container .ant-radio-button-wrapper,
.ant-btn.cont-header-btn {
    height: 2.1em;
    display: inline-flex;
    align-items: center;
}

.inner-tab-hd-container .ant-radio-button-wrapper {
    height: 1.9em;
}

#inner-tab-content .ant-table-wrapper .ant-table.ant-table-middle .ant-table-tbody>tr>td {
    padding: 10px 8px;
}

.inner-tab-hd-container {
    display: flex;
}

.inner-tab-hd-container .last-option {
    margin-left: auto !important;
    margin-right: 5px;
    padding: 2px 0.6em;
    border-color: var(--purple);
    background-color: var(--gray);
}

.tbl-other-btn {
    border-radius: 50%;
    border: 0;
    padding: 5px !important;
    background: inherit;
    transition: none;
    box-shadow: none;
    width: 30px;
    height: 30px;
}

.dd-hover-active .tbl-other-btn {
    background-color: var(--purple);
    color: white !important;
}

.tbl-other-btn:hover {
    background-color: var(--purple) !important;
}

tr:nth-child(odd).dd-hover-active .ant-table-cell-row-hover {
    background-color: var(--white) !important;
}

tr:nth-child(even).dd-hover-active .ant-table-cell-row-hover {
    background-color: var(--light-gray) !important;
}

tr:nth-child(even).dd-hover-active .ant-table-cell-row-hover,
tr:nth-child(odd).dd-hover-active .ant-table-cell-row-hover {
    color: var(--black);
}

.tbl-td-label {
    margin-bottom: 5px;
}

.ant-table-body {
    height: calc(100vh - 325px);
}

.ant-table-column-title .tbl-td-label {
    font-size: 0.85em;
    /* font-size: 12px; */
}

.dot3-icon-align {
    justify-content: center;
}

.ant-input-affix-wrapper.tbl-col-search {
    border-radius: 0;
    font-size: 0.9em;
    padding: 2px 6px;
}

/* Pagination */

.ant-pagination-options .ant-select-single {
    width: auto !important;
}

.custom-pagn,
.custom-pagn .ant-select-selection-item {
    color: var(--purple);
}

/* Image components */

.single-img-container {
    display: flex;
    align-items: center;
}

.group-img-container {
    display: flex;
    column-gap: 0.4em;
}